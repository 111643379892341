import React from "react";
import { Route, Routes } from "react-router-dom";
import TravelPlan from "../../components/Contingent/TravelPlan";
import Error404 from "../../pages/Error404";

const routes = [
  { path: "/", element: <TravelPlan /> },
  { path: "/travel-plan", element: <TravelPlan /> },
  {
    path: "*",
    element: () => {
      return setTimeout(() => {
        return <Error404 />;
      }, 1000);
    },
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

function SOARoutes({ defaultRoute }) {
  return (
    <Routes>
      {routes.map(({ path, element }, index) => {
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  );
}

export default SOARoutes;
