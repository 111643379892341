import {
  getAllArrivalAndDepartureDataEndPoint,
  getAllusersCountEndPoint,
  getAlplayersEndPoint,
  getCalendardataEndPoint,
  getCountryListEndPoint,
  getSingleArrivalDepartureGroupDataEndPoint,
  getSinglePlayerTrackedDataEndPoint,
  getSOAListEndPoint,
  getTrackedDataEndPoint,
  getTrackedDataOfSingleEndPoint,
  removePlayerFromPlanEndPoint,
} from "../../apiEndpoints.js/apiEndpoints";
import axiosInstance from "../../axiosInstance/axiosInstance";

export const getCountryListFunc = async () => {
  try {
    return axiosInstance.get(getCountryListEndPoint);
  } catch (error) {
    handleError();
  }
};
export const getSOAListFunc = async () => {
  try {
    return axiosInstance.get(getSOAListEndPoint);
  } catch (error) {
    handleError();
  }
};

export const getCalendarDataFunc = async (filter) => {
  try {
    return axiosInstance.post(getCalendardataEndPoint, filter);
  } catch (error) {
    handleError();
  }
};

export const getTrackedDataFunc = (data) => {
  try {
    let url = `${getTrackedDataEndPoint}/${data?.country ? data?.country : 0}?`;
    if (data?.sub_category_type_id) {
      url += `sub_category_type=${data.sub_category_type_id}&`;
    }
    if (data?.start_time) {
      url += `ts=${data.start_time}&`;
    }

    if (data?.end_time) {
      url += `te=${data.end_time}&`;
    }
    if (data?.limit) {
      url += `limit=${data.limit}&`;
    }
    if (data?.page) {
      url += `page=${data.page}&`;
    }
    if (data?.kit_id) {
      url += `kit_id=${data.kit_id}&`;
    }
    return axiosInstance.get(url);
  } catch (error) {
    handleError();
  }
};

export const getPlayerSingleTrackedDataFunc = (payload) => {
  try {
    let url = `${getSinglePlayerTrackedDataEndPoint}?kit_id=${payload.id}&page=${payload.currentPage}&`;

    if (payload?.start_time) {
      url += `ts=${payload.start_time}&`;
    }
    if (payload?.end_time) {
      url += `te=${payload.end_time}&`;
    }
    return axiosInstance.get(url);
  } catch (error) {
    handleError();
  }
};
export const getTrackedDataFuncForSingle = (allsportId) => {
  try {
    return axiosInstance.get(
      `${getTrackedDataOfSingleEndPoint}?all_sport_id=${allsportId}`
    );
  } catch (error) {
    handleError();
  }
};

export const getAllPlayersFunc = () => {
  try {
    return axiosInstance.get(getAlplayersEndPoint);
  } catch (error) {
    handleError();
  }
};

export const getAllUsersCountFunc = () => {
  try {
    return axiosInstance.get(getAllusersCountEndPoint);
  } catch (error) {
    handleError();
  }
};
export const getAllArrivalAndDepartureDataFunc = (
  travelTypeValue,
  otherOptions = {}
) => {
  let {
    transportMode,
    federation,
    arrival_date,
    arrival_time,
    departure_date,
    departure_time,
    soa_id,
  } = otherOptions;

  let url = `${getAllArrivalAndDepartureDataEndPoint}?type=${travelTypeValue}&`;
  if (transportMode) {
    url += `transportationMode=${transportMode}&`;
  }
  if (federation) {
    url += `countryName=${federation}&`;
  }
  if (arrival_date) {
    url += `date=${arrival_date}&`;
  }
  if (departure_date) {
    url += `date=${departure_date}&`;
  }

  if (arrival_time) {
    url += `time=${arrival_time}&`;
  }
  if (departure_time) {
    url += `time=${departure_time}&`;
  }
  if (soa_id) {
    url += `soa_id=${soa_id}&`;
  }
  try {
    return axiosInstance.get(url);
  } catch (error) {
    handleError();
  }
};

export const getSingleArrivalDepartureGroupDataFunc = (type, id) => {
  try {
    return axiosInstance.get(
      `${getSingleArrivalDepartureGroupDataEndPoint}/${id}`
    );
  } catch (error) {
    handleError();
  }
};
export const removePlayerFromFunc = (travellingPlanID, member_id) => {
  try {
    return axiosInstance.delete(
      `${removePlayerFromPlanEndPoint}/${travellingPlanID}/${member_id}`
    );
  } catch (error) {
    handleError();
  }
};
// Error handling function that logs the error and throws a more meaningful message
function handleError(error) {
  console.error("Error occurred:", error);
  throw {
    message: "Something went wrong! Please try again.",
    originalError: error,
  };
}
