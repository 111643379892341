import React, { useEffect, useState } from "react";
import { getSinglePlayerTrackedData } from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import SinglePlayerMapComponent from "../SinglePlayerMap/SinglePlayerMapComponent";
import GenericTable from "./Table";
import PaginationComp from "../pagination/PaginationComp";

const columns = [
  { label: "S.No", field: "s_no" },
  { label: "Date/Time", field: "date" },
  { label: "Address/Locations", field: "address" },
];

function SinglePlayerTracker() {
  const [currentPage, setcurrentPage] = React.useState(1);
  const [individualLatLong, setIndividualLatLong] = React.useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [persistData, setpersistData] = useState({});
  const store = useSelector(
    (store) => store?.TravelAdminReducer?.singlePLayerMapData
  );
  const store1 = useSelector((store) => console.log(store?.TravelAdminReducer));
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    reset,
  } = useForm();

  const handlePagination = (number) => {
    dispatch(getSinglePlayerTrackedData({ id, currentPage, ...persistData }));
  };
  // Watch for start_time and end_time to perform validation
  const startTime = watch("start_time");
  const endTime = watch("end_time");

  // Submit function with validation for start and end time
  const onSubmit = (data) => {
    if (!data?.date) {
      const now = new Date();
      const istDate = new Date(
        now.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
      );
      data.date = `${istDate.getFullYear()}-${String(
        istDate.getDate()
      ).padStart(2, "0")}-${String(istDate.getMonth() + 1).padStart(2, "0")}`;
    }

    if (!data?.start_time) {
      data.start_time = "00:00";
    }
    if (!data.end_time) {
      data.end_time = "23:59";
    }

    if (startTime && endTime && startTime >= endTime) {
      setError("end_time", {
        type: "manual",
        message: "End time must be after start time.",
      });
    } else {
      data.start_time =
        data.start_time && new Date(`${data.date}T${data.start_time}:00`);
      data.start_time =
        data?.start_time && Math.floor(data.start_time.getTime());
      data.end_time =
        data?.end_time && new Date(`${data.date}T${data.end_time}:00`);
      data.end_time = data?.end_time && Math.floor(data.end_time.getTime());
      setpersistData(data);
      dispatch(getSinglePlayerTrackedData({ ...data, id, currentPage }));

      // Dispatch action or perform other operations here
    }
  };

  // Fetch player details
  function fetchSinglePlayerData(id) {
    dispatch(getSinglePlayerTrackedData({ id, currentPage }));
  }
  useEffect(() => {
    fetchSinglePlayerData(id);
  }, [dispatch, id]);

  const trOnClickEvent = (ele) => {
    setIndividualLatLong(ele);
  };
  console.log(store);
  return (
    <React.Fragment>
      <PageTitle
        motherMenu="Player History"
        activeMenu={`Tracker-${store?.user?.fullname} ( ${id} )`}
        Button={() => (
          <Button onClick={() => navigate(-1)} className="mb-2" size="sm">
            Go Back
          </Button>
        )}
      />
      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <div className="col-lg-2">
                  <label>Date</label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date")}
                  />
                </div>
                <div className="col-lg-2">
                  <label>Start Time</label>
                  <input
                    type="time"
                    className="form-control"
                    {...register("start_time", {})}
                  />
                  {errors.start_time && (
                    <small className="text-danger">
                      {errors.start_time.message}
                    </small>
                  )}
                </div>
                <div className="col-lg-2">
                  <label>End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    {...register("end_time", {
                      required: startTime ? "End time is required" : false,
                    })}
                  />
                  {errors.end_time && (
                    <small className="text-danger">
                      {errors.end_time.message}
                    </small>
                  )}
                </div>
                <div className="col-lg-1 d-flex align-items-end">
                  <Button type="submit">Search</Button>
                </div>
                <div className="col-lg-1 d-flex align-items-end">
                  <Button
                    onClick={() => {
                      setIndividualLatLong({});
                      fetchSinglePlayerData(id);
                      setpersistData({});
                      reset();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <SinglePlayerMapComponent
        trackedData={store?.data}
        individualLatLong={individualLatLong}
      />

      <GenericTable
        columns={columns}
        data={store?.data || []}
        trOnClickEvent={trOnClickEvent}
        PaginationComp={
          <PaginationComp
            handlePagination={handlePagination}
            currentpage={currentPage}
            setcurrentPage={setcurrentPage}
            size={store?.totalPages || 1}
            gutter={true}
            variant={""}
            bg={true}
            circle={false}
          />
        }
      />
    </React.Fragment>
  );
}

export default SinglePlayerTracker;
