import React, { useEffect, useRef, useState, useMemo } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const SinglePlayerMapComponent = ({ individualLatLong, trackedData = [] }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  // Sort trackedData by timestamp
  const sortedData = useMemo(() => {
    return trackedData.sort((a, b) => a.timestamp - b.timestamp);
  }, [trackedData]);

  useEffect(() => {
    if (!mapContainerRef.current || !mapboxgl.accessToken) return;

    if (mapRef.current) {
      mapRef.current.remove();
    }

    try {
      const defaultCenter = [77.209, 28.6139];

      // Initialize the map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center:
          sortedData.length > 0
            ? [sortedData[0].longitude, sortedData[0].latitude]
            : defaultCenter,
        zoom: 12,
      });

      mapRef.current.on("load", () => {
        mapRef.current.addControl(
          new mapboxgl.NavigationControl(),
          "top-right"
        );

        // Clear existing markers
        markersRef.current.forEach((marker) => marker.remove());
        markersRef.current = [];

        // Check if individualLatLong has keys
        const showSingleMarker = Object.keys(individualLatLong).length > 0;

        if (showSingleMarker) {
          // Add a single marker for individualLatLong
          const { latitude, longitude, address } = individualLatLong;
          const singleMarker = new mapboxgl.Marker({ color: "red" })
            .setLngLat([longitude, latitude])
            .setPopup(
              new mapboxgl.Popup()
                .setHTML(` <div style="font-family: Arial, sans-serif; padding: 5px;">
                <div>Address: ${address}</div>
              </div>`)
            )
            .addTo(mapRef.current);

          markersRef.current.push(singleMarker);

          mapRef.current.setCenter([longitude, latitude]);
          mapRef.current.setZoom(14);
        } else {
          // Add markers for sortedData
          const polylineCoordinates = sortedData.map(
            ({ latitude, longitude, address }, index) => {
              const markerElement = document.createElement("div");
              markerElement.className = "custom-marker";
              markerElement.style.width = "30px";
              markerElement.style.height = "30px";
              markerElement.style.cursor = "pointer";

              const markerSvg = `
                <svg viewBox="0 0 24 36" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 0C5.383 0 0 5.383 0 12c0 9 12 24 12 24s12-15 12-24c0-6.617-5.383-12-12-12z" 
                        fill="#886CC0" 
                        stroke="white" 
                        stroke-width="1"/>
                  <circle cx="12" cy="12" r="6" fill="white"/>
                  <text x="12" y="15" text-anchor="middle" fill="black" font-size="10">${
                    index + 1
                  }</text>
                </svg>
              `;

              markerElement.style.backgroundImage =
                "url(data:image/svg+xml;base64," + btoa(markerSvg) + ")";
              markerElement.style.backgroundSize = "contain";
              markerElement.style.backgroundRepeat = "no-repeat";
              markerElement.style.backgroundPosition = "center";

              const popupContent = `
                <div style="font-family: Arial, sans-serif; padding: 5px;">
                  <div>Address: ${address}</div>
                </div>
              `;

              const marker = new mapboxgl.Marker(markerElement)
                .setLngLat([longitude, latitude])
                .setPopup(new mapboxgl.Popup().setHTML(popupContent))
                .addTo(mapRef.current);

              markersRef.current.push(marker);

              return [longitude, latitude];
            }
          );

          mapRef.current.addSource("polyline", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: polylineCoordinates,
              },
            },
          });

          mapRef.current.addLayer({
            id: "polyline",
            type: "line",
            source: "polyline",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#888",
              "line-width": 4,
            },
          });

          if (sortedData.length > 0) {
            const bounds = polylineCoordinates.reduce((bounds, coord) => {
              return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(polylineCoordinates[0], polylineCoordinates[0]));

            mapRef.current.fitBounds(bounds, {
              padding: 50,
              maxZoom: 15,
            });
          }
        }
      });
    } catch (error) {
      console.error("Error initializing map:", error);
    }

    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [sortedData, individualLatLong]);

  return (
    <div style={{ height: "60vh", width: "100%", position: "relative" }}>
      <div
        ref={mapContainerRef}
        style={{
          height: "100%",
          width: "100%",
          border: "1px solid lightgray",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative",
        }}
      />
    </div>
  );
};

export default React.memo(SinglePlayerMapComponent);
