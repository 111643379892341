import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({
  motherMenu,
  activeMenu,
  pageContent,
  Button,
  historyPopper = () => {},
  fetchData = () => {},
}) => {
  //   let path = window.location.pathname.split("/");
  let path = window.location.pathname
    .split("/")
    .filter((p) => p)
    .join("/");
  return (
    <div className="row page-titles mx-0">
      <div className="d-flex align-items-center ">
        <ol className="breadcrumb me-auto">
          <li className="breadcrumb-item active">
            <Link
              onClick={historyPopper}
              // to={`/${path}`}
            >
              {motherMenu}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${path}`}>{activeMenu}</Link>
          </li>
        </ol>
        {Button && <Button fetchData={fetchData} />}
      </div>
    </div>
  );
};

export default PageTitle;
