import React, { lazy, Suspense } from "react";
import Index from "./jsx";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { loginRequestWithToken } from "./store/actions/AuthActions";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App() {
  const { isAuthenticated, userType } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const jwtToken = searchParams.get("jwt_token");
    if (jwtToken) {
      dispatch(loginRequestWithToken(jwtToken)).then((res) => {
        const { success, data } = res.payload || {};
        if (success && data?.token != null) {
          setTimeout(() => {
            navigate(location.pathname);
          }, 1000);
        }
      });
    }
  }, [location, dispatch, navigate]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  if (isAuthenticated) {
    return (
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Index userType={userType} />
      </Suspense>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/page-register" element={<SignUp />} />
            <Route path="/page-forgot-password" element={<ForgotPassword />} />
          </Routes>
        </Suspense>
      </div>
    );
  }
}

export default App;
