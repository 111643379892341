import React, { useContext, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "react-bootstrap/Collapse";
/// Link
import { Link } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
//import LogoutPage from './Logout';

import { NSFMenuList, SOAMenuList, TravelAdminMenuList } from "./Menu";
import { useSelector } from "react-redux";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "SOA" || "Travel Admin",
  activeSubmenu: "",
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);
  const [userType] = useState(localStorage.getItem("userType"));
  const { userType: userTypeValue } = useSelector((store) => store.auth);

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  //let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

  React.useEffect(() => {}, [userType]);

  React.useEffect(() => {
    if (userTypeValue === "travel_module_admin") {
      setState({ active: "Travel Admin" });
    } else if (userTypeValue === "sports_olympic_association") {
      setState({ active: "SOA" });
    }
  }, [userTypeValue]);

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {/* For travel_module_admin */}
          {userTypeValue === "travel_module_admin" &&
          Array.isArray(TravelAdminMenuList)
            ? TravelAdminMenuList.map((data, index) => (
                <li
                  key={index}
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                >
                  {data.content && data.content.length > 0 ? (
                    <React.Fragment>
                      <Link
                        to="#"
                        className="has-arrow"
                        onClick={() => handleMenuActive(data.title)}
                      >
                        {data.iconStyle}{" "}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title}>
                        <ul
                          className={`mm-collapse ${
                            state.active === data.title ? "mm-show" : ""
                          }`}
                        >
                          {data.content.map((subData, subIndex) => (
                            <li
                              key={subIndex}
                              className={`${
                                state.activeSubmenu === subData.title
                                  ? "mm-active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={subData.to}
                                onClick={() =>
                                  handleSubmenuActive(subData.title)
                                }
                              >
                                {subData.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              ))
            : userTypeValue === "national_sports_federation" &&
              Array.isArray(NSFMenuList)
            ? NSFMenuList?.map((data, index) => (
                <li
                  key={index}
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                >
                  {data.content && data.content.length > 0 ? (
                    <React.Fragment>
                      <Link
                        to="#"
                        className="has-arrow"
                        onClick={() => handleMenuActive(data.title)}
                      >
                        {data.iconStyle}{" "}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title}>
                        <ul
                          className={`mm-collapse ${
                            state.active === data.title ? "mm-show" : ""
                          }`}
                        >
                          {data.content.map((subData, subIndex) => (
                            <li
                              key={subIndex}
                              className={`${
                                state.activeSubmenu === subData.title
                                  ? "mm-active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={subData.to}
                                onClick={() =>
                                  handleSubmenuActive(subData.title)
                                }
                              >
                                {subData.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              ))
            : userTypeValue === "sports_olympic_association" &&
              Array.isArray(SOAMenuList)
            ? SOAMenuList?.map((data, index) => (
                <li
                  key={index}
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                >
                  {data.content && data.content.length > 0 ? (
                    <React.Fragment>
                      <Link
                        to="#"
                        className="has-arrow"
                        onClick={() => handleMenuActive(data.title)}
                      >
                        {data.iconStyle}{" "}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title}>
                        <ul
                          className={`mm-collapse ${
                            state.active === data.title ? "mm-show" : ""
                          }`}
                        >
                          {data.content.map((subData, subIndex) => (
                            <li
                              key={subIndex}
                              className={`${
                                state.activeSubmenu === subData.title
                                  ? "mm-active"
                                  : ""
                              }`}
                            >
                              <Link
                                to={subData.to}
                                onClick={() =>
                                  handleSubmenuActive(subData.title)
                                }
                              >
                                {subData.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}{" "}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              ))
            : null}
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
