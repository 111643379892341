import { MdModeOfTravel } from "react-icons/md";
export const MenuList = [];

export const TravelAdminMenuList = [
  {
    title: "Travel Admin",
    iconStyle: <MdModeOfTravel size={25} />,
    content: [
      { title: "Calendar", to: "calendar" },
      { title: "Arrival", to: "arrival" },
      { title: "Departure", to: "departure" },
      // { title: "Tracker", to: "tracker" },
    ],
  },

  ...MenuList,
];
export const NSFMenuList = [
  {
    title: "NSF",
    iconStyle: <i className="fas fa-home"></i>,
    content: [{ title: "Travel Plan", to: "travel-plan" }],
  },
  ...MenuList,
];
export const SOAMenuList = [
  {
    title: "SOA",
    iconStyle: <i className="fas fa-home"></i>,
    content: [{ title: "Travel Plan", to: "travel-plan" }],
  },
  ...MenuList,
];
